<template>
  <div
    class="incident-item"
    @click="goToIncidentPage"
  >
    <div class="incident-item__title">
      <IconAlert class="mr-3" />
      Инцидент: {{ incident.type.title }}
    </div>
    <div class="incident-item__sub-title">
      <IconEmployee class="mr-2" />
      <b class="mr-auto">
        {{ incident.vacancy.title }}
      </b>
      <span class="incident-item__shift-date">
        Начало смены: {{ incident.relay.startDate }} <b>{{ incident.relay.startTime }}</b>
      </span>
    </div>
    <div class="incident-item__user-card">
      <div class="incident-item__laborer">
        Исполнитель:
      </div>
      <OfferItemChatUserCard
        v-if="incident.laborer"
        :selectedUser="null"
        :noBorder="true"
        :laborer="incident.laborer"
      />
    </div>
    <div class="incident-item__footer">
      <b class="incident-item__number mr-3">{{ incident.relay.id.slice(-6) }}</b>
      <span class="mr-1">{{ incident.startDate }}</span>
      <b class="mr-auto">{{ incident.startTime }}</b>
      <span>{{ incident.status }}</span>
    </div>
  </div>
</template>

<script>
import OfferItemChatUserCard from '@/components/offer/OfferItemChatUserCard'
import IconEmployee from '@/components/common/icons/IconEmployee'
import IconAlert from '@/components/common/icons/IconAlert'
import routeList from '@/router/labels'

export default {
  name: 'IncidentItem',
  components: {
    OfferItemChatUserCard,
    IconEmployee,
    IconAlert
  },
  props: {
    incident: Object
  },
  computed: {
    currentOffice () {
      return this.$store.state.office.userOffice
    },
  },
  methods: {
    goToIncidentPage () {
      this.$router.push({
        name: routeList.OFFICE_INCIDENT_ITEM_ROUTE_NAME,
        params: { id: this.currentOffice.id, incidentId: this.incident.id },
      })
    }
  }
}
</script>

<style lang="sass">
.incident-item
  padding: 15px 30px
  margin-bottom: 30px
  background: #FFFFFF
  border: 1.5px solid rgba(0, 0, 0, 0.1)
  box-sizing: border-box
  box-shadow: 0px 5px 15px rgba(117, 117, 117, 0.1)
  border-radius: 6px
  cursor: pointer

  &__title,
  &__sub-title,
  &__user-card
    display: flex
    align-items: center
    padding: 15px 0
    border-bottom: 1px solid rgba(0, 0, 0, 0.1)

  &__user-card
    flex-direction: column
    align-items: start
    padding-bottom: 0

  &__laborer
    margin-top: 10px
    margin-bottom: -5px
    font-size: 14px
    opacity: 0.7

  &__footer
    display: flex
    padding: 15px 0
    font-size: 16px
    line-height: 20px

    b, span
      opacity: 0.2

    b.incident-item__number
     opacity: 0.4


  &__title
    font-weight: 800
    font-size: 20px
    line-height: 24px
    font-size: 16px
    line-height: 20px

  &__shift-date
    color: rgba(0, 0, 0, 0.7)

</style>
