<template>
  <div class="date-tabs">
    <div
      v-for="it in dateOptions"
      :key="it"
      class="date-tabs__tab"
      :class="{'date-tabs__tab--active': it === dateFilterSelected}"
      @click="changeDateFilter(it)"
    >
      {{ $t(`info.payments.date_options.${it}`) }}
      <template v-if="it === 'period' && dateFilterSelected !== 'all'">
        <span>
          {{ startDate | moment('DD.MM.YY') }}
          <span class="text-gray">-</span>
          {{ endDate | moment('DD.MM.YY') }}
        </span>
        <flat-pickr
          ref="flatpickr"
          v-model="dateRange"
          class="flatpickr"
          type="Timestamp"
          :config="startDateConfig"
          @on-close="updateCalendar"
        />
      </template>
    </div>
  </div>
</template>
<script>
import { Russian } from 'flatpickr/dist/l10n/ru.js'

export default {
  name: 'AppDateFilter',
  props: {
    disabled: Boolean
  },
  data: () => ({
    dateFilterSelected: 'all',
    dateRange: [],
    startDateConfig: {
      locale: Russian,
      dateFormat: 'Z',
      altFormat: 'Z',
      mode: 'range',
    },
    dateOptions: ['all', 'last_week', 'last_month', 'period'],
  }),
  computed: {
    startDate () {
      if (!this.dateRange.length) {
        return null
      }
      const [startDate] = this.dateRange.split(' — ')
      return startDate
    },
    endDate () {
      if (!this.dateRange.length) {
        return null
      }
      if (!this.dateRange.includes('—')) {
        return this.dateRange
      }
      const [, endDate] = this.dateRange.split(' — ')
      return endDate
    },
    params () {
      const params = {}

      if (this.startDate) {
        params.startDate = this.startDate
      }

      if (this.endDate) {
        params.endDate = this.$moment(this.endDate).add(1, 'day').toISOString()
      }

      return params
    }
  },
  watch: {
    dateFilterSelected (val, prev) {
      if (val === 'period' && prev === 'all') {
        const startDate = this.$moment().subtract(7, 'days').toISOString()
        const endDate = this.$moment().toISOString()
        this.dateRange = `${startDate} — ${endDate}`
      }
    },
  },
  methods: {
    changeDateFilter (type) {
      if (this.disabled) {
        return
      }

      this.dateFilterSelected = type

      if (type === 'all') {
        this.dateRange = []
      } else if (type === 'last_week') {
        const startDate = this.$moment().subtract(7, 'days').toISOString()
        const endDate = this.$moment().toISOString()
        this.dateRange = `${startDate} — ${endDate}`
      } else if (type === 'last_month') {
        const startDate = this.$moment().subtract(1, 'month').toISOString()
        const endDate = this.$moment().toISOString()
        this.dateRange = `${startDate} — ${endDate}`
      }

      if (type === 'period') {
        this.$nextTick(() => {
          this.$refs.flatpickr[0].fp.open()
        })
        return
      }

      this.$emit('changeFilters', this.params)
    },
    updateCalendar () {
      setTimeout(() => {
        this.$emit('changeFilters', this.params)
      }, 0)
    }
  }
}
</script>

<style lang="sass" scoped>
.date-tabs
  display: flex
  padding-left: 25px
  padding-bottom: 15px
  margin-bottom: 15px
  font-weight: 600
  font-size: 16px
  line-height: 20px
  color: black

  &__tab
    position: relative
    opacity: 0.4
    cursor: pointer
    margin-right: 20px

    &--active
      opacity: 1
</style>
