<template>
  <AppMainContainer center-class="offer">
    <template #subheader>
      <PageHeader :isAdmin="isOrganizationRoot && !officeExists" />
      <OfficeSelect />
    </template>
    <template #center>
      <div
        v-if="officeExists"
        class="d-flex"
      >
        <h1 class="mr-auto">
          {{ $t('header.navigational.info.incidents') }}
        </h1>
        <AppDateFilter
          :disabled="loader"
          @changeFilters="changeFilters"
        />
      </div>

      <template
        v-if="!loader"
      >
        <template v-if="officeExists">
          <IncidentItem
            v-for="(it, i) in incidentList"
            :key="i"
            :incident="it"
          />
        </template>
      </template>
      <AppLoader
        v-if="loader"
        class="full-height"
      />

      <div
        v-if="officeExists && !loader && !incidentList.length"
        class="empty-list"
      >
        {{ $t('empty_list') }}
      </div>
    </template>
  </AppMainContainer>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import userUtil from '@/utils/user-util'
import OfficeSelect from '@/components/common/OfficeSelect'
import AppMainContainer from '@/components/common/layout/AppMainContainer'
import AppDateFilter from '@/components/common/AppDateFilter'
import AppLoader from '@/components/AppLoader'
import IncidentItem from '@/components/incidents/IncidentItem'
import PageHeader from '@/components/subheaders/PageHeader.vue'

export default {
  components: {
    PageHeader,
    AppMainContainer,
    AppDateFilter,
    IncidentItem,
    AppLoader,
    OfficeSelect,
  },
  data () {
    return {
      filters: {},
    }
  },
  computed: {
    ...mapState({
      loader: (state) => state.incident.loader
    }),
    officeExists () {
      return this.$store.state.office.userOffice && this.$store.state.office.userOffice.id
    },
    incidentList () {
      return this.$store.state.incident.items
    },
    user () {
      return this.$store.state.users.info
    },
    isOrganizationRoot () {
      return userUtil.isRoot(this.user) || userUtil.isAdmin(this.user)
    },
  },
  watch: {
    officeExists (val, prev) {
      if (val && !prev) {
        this.fetchIncidentList(this.filters)
      }
    },
    '$route.params.id': function (officeId) {
      if (!officeId) {
        return
      }
      this.fetchIncidentList(this.filters)
    }
  },
  async created () {
    if (this.officeExists || !userUtil.isRoot(this.user)) {
      this.fetchIncidentList(this.filters)
    }
  },

  methods: {
    ...mapActions({
      fetchIncidentList: 'incident/fetchIncidentList',
    }),
    changeFilters (params) {
      this.filters = { ...params }
      this.fetchIncidentList(this.filters)
    },
  },
}
</script>
